/* News Aggregator */
// MAKE LIQUID!!!!
// 1. Mixins
// 2. Global Styles
// 3. Expanded View
// 4. Condensed View
// 5. Comment Count and Read More
$news-entry-padding-vert: 28.5px;
$news-entry-padding-horz: 20px;
$news-entry-spacing: 0;
$news-entry-spacing-top: 20.5px;
$news-article-title-size: 24px;
$news-article-title-color: $primary-color;
$news-article-title-color-hover: lighten($primary-color, 10%);
// 1. Mixins (Remove when liquid)
@mixin news-entry {
  &:first-of-type {
    margin-top: $news-entry-spacing-top;
    padding-top: 0;
    border-top: none;

    @media screen and (max-width: 767px) {
      margin-top: 15px;
    }
  }
  border-top: 1px solid $gray;
  padding: $news-entry-padding-vert 0;
  display: flex;
  flex-flow: row;
  justify-content: center;
}
@mixin news-entry-image() {
  @include aspect-ratio(150, 100);
  width: 100%;
  flex-basis: 100%;
  max-width: 150px; // 140px;
  max-height: 100px; // 105px;
  display: inline-block;
  margin: 0 $news-entry-padding-horz 0 0;
  opacity: 1;
  transition: opacity $transition-standard;
  position: relative;

  &:hover {
    opacity: 0.8;
  }
}
@mixin news-entry-content {
  width: 100%;
  flex-basis: 100%;
  align-self: flex-start;
}
@mixin news-entry-header {
  font-family: $secondary-font-bold;
  font-size: $news-article-title-size;
  font-weight: 700;
  text-transform: none;
  line-height: 1.2;

  > a {
    color: $news-article-title-color;
    font-weight: 700;
    transition: color $transition-standard, border $transition-standard;
    text-decoration: underline;

    &:hover {
      // color: $news-article-title-color-hover;
      color: $secondary-color;
      text-decoration: underline;
    }
  }
}
@mixin news-entry-date-author {
  font-family: $fontTwo;
  color: $primary-color;
  font-size: em(12);
  font-weight: 500;
  line-height: 1.25;
  display: inline;
  margin-right: 4px;
}
@mixin news-entry-teaser {
  font-family: $fontTwo;
  font-size: em(14);
  font-weight: 500;
  line-height: 1.25;
  text-transform: none;
  color: #333;
  margin: 0.75em 0;
}
// 2. Global styles
.aggHeader {
  @include special-header;
}

.newsAggregatorElement {
  .dateAuthor {
    line-height: 1;
    margin-top: 5px;
  }

  .newsAuthor,
  .newsDate {
    @include news-entry-date-author;
  }

  .item + .extendedOptions {
    // padding-top: 0;
    // margin-left: 30px;
    // border-top: 1px solid $grey-lt;
    // border: none;
    // padding-top: $news-entry-padding-vert;
    padding-top: 5px;
  }
  // 3. Expanded Previews
  .expanded {
    @include news-entry;

    img {
      display: none;
    }

    .background-cover-img {
      // _custom/_custom.js generates this class and aranges the dom for this layout (to match the condensed view)
      @include news-entry-image
    }

    .newsItemElement {
      @include news-entry-content;

      h4 {
        @include news-entry-teaser;
      }
    }

    .newsItemHeader {
      h2 {
        @include news-entry-header;
      }
    }

    .newsText {
      clear: both;
      margin-top: 1em;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-transform: none;
        font-weight: 600;
        font-size: 1em;
        letter-spacing: normal;
        margin: 0 0 0.5em;
        padding: 0;
      }

      ul {
        list-style-type: disc;
      }
    }
  }
  // 4. Condensed Previews
  .condensed {
    @include news-entry;

    img {
      display: none;
    }

    .background-cover-img {
      // .has-mobile-nav & {
      //   padding: 0 15px 15px 0;
      // }
      @include news-entry-image
    }

    > ul,
    .details {
      list-style-type: none;
      @include news-entry-content h4 {
        @include news-entry-header;
      }
    }

    .teaser {
      @include news-entry-teaser;

      &:empty {
        display: none;
      }
    }
  }
}
// 5. Read more and comments
.commentCount {
  .button-commentCount {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    line-height: 1;

    &:hover {
      background: none;
    }

    .button-image-right {
      display: none;
    }

    .button-content {
      margin: 0;
      padding: 0;
      position: relative;
      border: none;
      background: none;
      color: $link-color;
      font-weight: 600;
      top: 2px;
      transition: all $transition-standard;

      &:hover {
        background: none;
        border: none;
        color: $hover-color;
      }

      &:before {
        content: "";
        display: inline-block;
        position: relative;
        font-family: FontAwesome;
        line-height: 1;
        content: "\f0e5";
        top: -1px;
        right: 0;
        padding-right: 0.25em;
      }
    }
  }
}

.commentsAndMore {
  line-height: 1;
  margin: 5px 0 0;

  .readMore {
    display: inline-block;
    margin-right: 0.5em;
    @media screen and (max-width: 767px) {
      display: none;
    }

    a {
      margin: 0;
    }
    // .button-small{
    //   font-family: $fontTwo;
    //   font-size: 12px;
    //   @include font-smoothing;
    //   font-weight: 700;
    //   background-color: $primary-color;
    //   // border: 1px solid $blue-lt;
    //   color: #FFF;
    //   transition: background-color $transition-linear;
    //   &:hover{
    //     background-color: $secondary-color;
    //   }
    //
    // }
  }
}
// 7. Responsive
@include page-element-break(360) {
  .newsAggregatorElement {
    .condensed,
    .expanded {
      // flex-wrap: wrap;
      .background-cover-img {
        margin: 0 15px $news-entry-padding-vert 0;
      }

      ul li {
        .readMore {
          display: none;
        }
      }
    }
  }
}
// Hoping to get rid of these gross mqs when we figure out our breakpoints and fully implement bootstrap
// Until then, these edge cases will have to be defined outside of the "page-element-break" mixin
@media only screen and (min-width: 769px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-3,
    .col-md-6 {
      .newsAggregatorElement {
        .condensed,
        .expanded {
          flex-wrap: wrap;

          .background-cover-img {
            margin: 0 0 $news-entry-padding-vert;
          }
        }
      }
    }
  }

  .layout-25-50-25 {
    .col-md-3,
    .col-md-6 {
      .newsAggregatorElement {
        .condensed,
        .expanded {
          flex-wrap: wrap;

          .background-cover-img {
            margin: 0 0 $news-entry-padding-vert;
          }
        }
      }
    }
  }
}

.gray-background-wrap {
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}

.layout-66-33 {
  .fw-content {
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
}

.custom-card {
  .newsAggregatorElement {
    @media screen and (max-width: 767px){
      margin: 0;
    }
  }
}

.col-md-8,
.col-sm-12 {
  .home & {
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
