// 5. Site Footer
.snFooterContainer {
  background-color: $primary-color;
  padding: 20px 0;
  min-height: 375px;
}

#siteFooter {
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 0 $page-element-margin;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  > ul {
    width: 100%;
    margin-top: $page-element-margin;
    padding: 30px $page-element-margin;
    @include displayCenter($maxWidth);
    color: #FFF;
    @media screen and (max-width: 767px) {
      display: block;
    }

    li {
      display: inline-block;
      padding: 0 .2em;
      min-height: 20px;

      a {
        display: inline-block;
        color: #FFF;
      }

      &:hover {
        display: inline-block;
      }
    }
  }
}

#siteFooter li#poweredByNGIN {
  a {
    display: block;
    width: 120px;
    height: 20px;
    position: relative;

    &:after {
      content: url($asset-path-for+'/logo_images/logo.svg');
      display: block;
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
    }

    img {
      display: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


// Ajaxed Additions
.footer-logo-container{
  width: 100%;
  border-bottom: 2px solid #FFF;
  padding-bottom: 30px;
  margin: $page-element-margin;
  .footer-logo{
    margin: 20px auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    max-width: 250px;
    width: 100%;
    @include aspect-ratio($site-logo-width, $site-logo-height);

  }
}
.footer-links{
  margin: $page-element-margin;
  h3{
    color: #FFF;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: .5em;
  }
  a{
    color: #FFF;
    font-size: 14px;
    line-height: 1;
    &:hover{
      color: #FFF;
    }
  }
  .text p{
    margin: 0;
  }
}
.footer-social{
  .sn-social-media-list{
    font-size: 30px;
    &.light .sn-social-media-icon{
      background-color: $primary-color;
      color: #ccc;
      border: 2px solid #ccc;
      &:hover {
        color: #fff;
        border: 2px solid #fff;
      }
    }
  }
  .icon-instagram {
    display: none;
  }
}
@media screen and (max-width: 700px){
  #siteFooter {
    justify-content: center;
  }
  .footer-links{
    display: none;
    &.footer-social{
      display: block;
    }
  }
}
