/* Call to Action Element */
// 1. Default Styles
// 2. Variations
// 3. Edge Case Fixes
// 4. Fix for tall text in short container
// 5. Adjustments for Small Columns & Mobile Views
// 6. Accomidation for touch screens
// 1. Default Styles
.sn-call-to-action,
.sn-call-to-action * {
  box-sizing: border-box;
}

.sn-call-to-action {
  cursor: pointer;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }

  .sn-call-to-action-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 1;
    text-align: center;
  }

  .sn-call-to-action-overlay-inner {
    display: table;
    width: 100%;
    height: 100%;
  }

  .sn-call-to-action-overlay-text {
    display: table-cell;
    font-size: rem(10);
    height: 100%;
    line-height: 1;
    vertical-align: middle;
  }

  .sn-call-to-action-title {
    color: #fff;
    font-family: $secondary-font-bold;
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;
    // margin-bottom: 0.3em;

    @media screen and (max-width: 767px) {
      font-size: 22px;
      letter-spacing: 0.5px;
    }
  }

  .sn-call-to-action-subtitle {
    color: #fff;
    font-family: $secondary-font;
    font-size: rem(16);
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 0;

    a,
    span {
      margin: 0 0.25em;
      color: #fff;
      display: inline-block;
    }
  }
}

.sn-call-to-action .sn-call-to-action-subtitle:first-of-type > * {
  padding-top: 5px;
}
// 2. Variations
/* Slide Up */
.sn-call-to-action.sn-variation-slide-up {
  .sn-call-to-action-overlay {
    background: $link-color_opacity;
    top: auto;
    height: 0;
    transition: height 0.5s ease;
  }

  .sn-call-to-action-title {
    margin-bottom: 20px;

    &:only-child {
      margin-bottom: 0.3em;
    }
  }

  .sn-call-to-action-subtitle {
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &:hover {
    .sn-call-to-action-overlay {
      height: 100%;
    }

    .sn-call-to-action-subtitle {
      opacity: 1;
    }
  }
}
/* Zoom */
.sn-call-to-action.sn-variation-zoom {
  img {
    transform: scale(1);
    transition: transform 0.5s ease;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}
/* Text Zoom */
.sn-call-to-action.sn-variation-text-zoom {
  .sn-call-to-action-overlay-text {
    transform: scale(1);
    transition: transform 0.5s ease;
  }

  &:hover {
    .sn-call-to-action-overlay-text {
      transform: scale(1.1);
    }
  }
}
/* Border */
.sn-call-to-action.sn-variation-border {
  .sn-call-to-action-overlay {
    box-shadow: inset 0 0 0 0 transparent, inset 0 0 0 0 transparent;
    transition: box-shadow 0.5s ease-in;
  }

  &:hover {
    .sn-call-to-action-overlay {
      box-shadow: inset 0 0 0 10px $link-color_opacity, inset 0 0 0 10px $link-color_opacity;
    }
  }
}
// 3. Edge Case Fixes
/* mirrored CTA preview img alignment fixes */
.pe25 .elementPreview img,
.pe33 .elementPreview img {
  width: 100%;
  margin: 0;
}
// 4. Fix for tall text in short container
.sn-call-to-action.sn-variation-tall-text {

  @media screen and (max-width: 1024px) {
    height: 90px !important;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    max-width: initial;
    min-width: 100%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 1024px) {
      height: 90px !important;
    }
  }
}

.sn-call-to-action.sn-variation-zoom.sn-variation-tall-text {
  &:hover {
    img {
      transform: scale(1.2) translate(-40%, -40%);
    }
  }
}
// 5. Adjustments for Small Columns & Mobile Views
/* paragraph spacing */
.pe25 .sn-call-to-action-overlay-text p {
  margin-bottom: 0.15em;
}
@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .col-md-3 .sn-call-to-action,
  .col-md-4 .sn-call-to-action {
    .sn-call-to-action-title {
      font-size: rem(20);
    }

    .sn-call-to-action-subtitle {
      font-size: rem(14);
    }
  }
}
@media only screen and (max-width: 350px) {
  .sn-call-to-action {
    .sn-call-to-action-title {
      font-size: rem(20);
    }

    .sn-call-to-action-subtitle {
      font-size: rem(14);
    }
  }
}
// 6. Accomodation for touch screens
.touch-screen {
  .sn-call-to-action.sn-variation-slide-up,
  .sn-call-to-action.sn-variation-slide-up:focus,
  .sn-call-to-action.sn-variation-slide-up:hover {
    .sn-call-to-action-overlay {
      height: 100%;
      transition: none;
    }

    .sn-call-to-action-subtitle {
      opacity: 1;
      transition: none;
    }
  }

  .sn-call-to-action.sn-variation-border,
  .sn-call-to-action.sn-variation-border:focus,
  .sn-call-to-action.sn-variation-border:hover {
    .sn-call-to-action-overlay {
      transition: none;
      box-shadow: inset 0 0 0 10px $link-color_opacity, inset 0 0 0 10px $link-color_opacity;
    }
  }

  .sn-call-to-action.sn-variation-text-zoom,
  .sn-call-to-action.sn-variation-text-zoom:focus,
  .sn-call-to-action.sn-variation-text-zoom:hover {
    .sn-call-to-action-overlay-text {
      transition: none;
      transform: scale(1);
    }
  }
}
// Custom Element
.custom-cta-container {
  @include compact;

  .column {
    padding: 0;
  }

  .column-2 {
    box-shadow: 1px 0 0 0 $primary-color, -1px 0 0 0 $primary-color;
    z-index: 2;
  }
}

.custom-cta {
  .pageElement {
    padding: 15px;
    background-color: #FFF;

    @media screen and (max-width: 767px) {
      padding: 8px;
    }
  }

  .sn-call-to-action-title {
    @include heading-line("center",#FFF);
  }

  .sn-variation-default {
    box-shadow: 0 0 0 5px $secondary-color;

    .sn-call-to-action-overlay {
      background: rgba(0, 0, 0, 0.7);
    }
  }
}

.custom-cta:hover {
  .sn-call-to-action-overlay {
    background: linear-gradient(to bottom, #000, rgba(0, 28, 144, 0.5));
  }
}
