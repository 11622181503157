#siteContainer {

  @media screen and (max-width: 767px) {
    background-color: #fff !important;
  }

  .edit_mode & {
    margin-top: 15px;
  }

}

#yieldContent {

  .home & {
    @media screen and (max-width: 767px) {
        padding-top: 82px;
    }
  }

  @media screen and (max-width: 767px) {
    overflow: hidden;
    padding-top: 5px;
  }

  .edit_mode & {
    padding-top: 15px;
  }

}

.social-media-icons {

  .sn-social-media-list.light {
    display: flex;

    @media screen and (max-width: 1024px) {
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 767px) {
      justify-content: center;
      align-items: center;
    }

    .sn-social-media-icon {
      background-color: $primary-color;
      color: #fff;
      font-size: 2em;

      &:hover {
        color: $secondary-color;
      }

      @media screen and (max-width: 1024px) {
        background: none;
        border: 1px solid #fff;
      }

      @media screen and (max-width: 767px) {
        padding-left: 0;
        background: none;
        border: 1px solid #fff;
      }

    }

    .icon-instagram {
      display: none;
    }

  }
}

.mediaSlider {
  margin: 0;

  > h2:empty,
  > p:empty {
    display: none;
  }
}

.video-background {
  h3 {
    @include heading-line("center",#FFF);
  }
}
