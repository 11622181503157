$sn-pageManager-width: 60px;

%standard-width {
  max-width: $maxWidth;
  width: 100%;
  margin: 0 auto;
}

%full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  .page-manager-visible & {
    width: calc(100vw - #{$sn-pageManager-width});
  }
}

%full-width-image-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full-width {
  .user_mode & {
    @extend %full-width;
  }

  &.layout-100 {
    padding-left: 0;

    .column {
      padding-left: 0;
      padding-right: 0;
      float: none;
    }
  }
}

.full-width-container,
.gray-background-wrap,
.theme-sub-nav {
  .user_mode & {
    @extend %full-width;
  }

  .fw-content {
    @extend %standard-width;
    padding: 0 $page-element-margin;
  }
}
@mixin no-padding {
  .column {
    padding-left: 0;
    padding-right: 0;
  }
}

.background-image {
  .user_mode & {
    display: none;
  }

  .edit_mode & {
    &:before {
      max-width: 300px;
      margin: 0 auto;
      display: block;
      padding: 1em;
      text-align: center;
      content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
    }

    img {
      max-width: 300px;
      margin: 0 auto;
      border: 2px solid #FFF;
      box-shadow: 0 0 15px rgba(0,0,0,.5);
    }
  }
}

.background-cover-img {
  @extend %full-width-image-background;
}
@mixin compact {
  &.pageEl .pageElement {
    margin-bottom: 0;
    margin-top: 0;
  }

  .pageEl .pageElement {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.compact {
  &.pageEl .pageElement {
    margin-bottom: 0;
    margin-top: 0;
  }

  .pageEl .pageElement {
    margin-bottom: 0;
    margin-top: 0;
  }

  &-top {
    &.pageEl .pageElement {
      margin-top: 0;
    }

    .pageEl .pageElement {
      margin-top: 0;
    }
  }

  &-bottom {
    &.pageEl .pageElement {
      margin-bottom: 0;
    }

    .pageEl .pageElement {
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width: 767px) {
  .compact:not(.layout-100) .pageEl .pageElement {
    margin-bottom: 25px;
  }

  .compact:not(.layout-100) .column:last-of-type .pageEl:last-of-type .pageElement {
    margin-bottom: 0;
  }
}

.layoutContainer {
  &.padding {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.padding-top {
    padding-top: 40px;
  }

  &.padding-bottom {
    padding-bottom: 40px;
  }
}

.center-align {
  &.layoutContainer,
  .fw-content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}

.maintain-ratio .video_aspect_ratio {
  padding-bottom: 0;
}

.textBlockElement > h3:only-child {
  margin-bottom: 0;
}

.element-header {
  .textBlockElement > h3 {
    @include special-header;
  }
}

.custom-news-slideshow {
  background-image: url("../images/news_band_gradient.jpg");
  background-size: cover;
  background-position: center;

  .user_mode & {
    @extend %full-width;
  }
}

.gray-background-wrap {
  background-color: #a2a9af;
  background-image: url("../images/background_grass.jpg");
  background-size: cover;
  background-position: center;
  padding: 20px 0;

  .user_mode & {
    @extend %full-width;
  }
}

.video-background {
  background-image: url("../images/blue_video_band_background.jpg");
  background-size: cover;
  background-position: center;
  padding: 20px 0;

  .user_mode & {
    @extend %full-width;
  }
}

.video-background-wrap {
  background-color: #a2a9af;
  background-image: url("../images/blue_video_band_background.jpg");
  background-size: cover;
  background-position: center;
  padding: 20px 0;
  .video-background {
    background-image: none;
  }
  .user_mode & {
    @extend %full-width;
  }
}

.white-background {
  background-color: #FFF;
}

.custom-card {
  .pageElement {
    padding: 20px;
    background-color: #FFF;
  }
}

.hide-preview-mobile {
  @media screen and (max-width: 767px) {
    .newsAggregatorElement .condensed .teaser,
    .newsAggregatorElement .expanded .newsItemElement h4,
    .newsAggregatorElement .expanded .newsText {
      display: none;
    }
  }
}

.fix-width {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  iframe {
    @media screen and (max-width: 766px) {
      width: 736px !important;
    }
  }
}
