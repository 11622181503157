.site-presenter:not(.home) {
  display: none;
}

.site-presenter {
  .home & {
    display: flex;
  }
  max-width: 245px;
  width: 100%;
  flex-flow: column-reverse;
  justify-content: center;
  position: absolute;
  right: 0;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    max-width: 215px;
  }

  @media screen and (max-width: 767px) {
    max-width: 270px;
  }

  .edit_mode & {
    display: none;
  }

  .presenter-prefix {
    font-size: 12px;
    font-family: $fontOne;
    text-transform: uppercase;
    margin: 2px 0;
    margin-top: 7px;
    color: $primary-color;
    padding-left: 45px;
    z-index: 1;

    @media screen and (max-width: 1024px) {
      margin-top: 2px;
    }

    @media screen and (max-width: 767px) {
      margin: 0;
      margin-top: 15px;
      font-size: 10px;
      font-weight: bold;
      padding-left: 50px;
    }
  }

  a {
    z-index: 1;
    padding: 10px 10px 10px 15px;

    @media screen and (max-width: 1024px) {
      padding: 4px 10px 10px 15px;
    }
  }

  img {
    max-width: 150px;
    display: block;

    @media screen and (max-width: 767px) {
      max-width: 135px;
    }
  }

  br {
    display: none;
  }

  &:before {
    z-index: 1;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    border-top: 71px solid #fff;
    border-left: 9px solid #fff;
    border-right: 9px solid #fff;
    border-bottom: 5px solid #fff;
    height: 0;
    max-width: 165px;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      border-top: 55px solid #fff;
      border-left: 4px solid #fff;
    }

    @media screen and (max-width: 767px) {
      top: 11px;
      border-top: 64px solid #fff;
    }
  }

}
